import services from "../axios/dbManag";

export const getInfoTesserato = (clientInfo, token, codiceTessera) => {
  return services.baseApiQrCode
    .post("v1/tesseramento/qrcode/view", {
      ...clientInfo,
      username: clientInfo.username,
      password: clientInfo.password,
      cod_tessera: codiceTessera ? codiceTessera : clientInfo.cod_tessera,
      crypt: clientInfo.crypt,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
export const loginQr = (credentials) => {
  return services.baseApiQrCode
    .post("/Login", {
      ...credentials,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
