<template>
  <div class="mx-auto my-auto text-center" v-if="isLoading">
    <div class="spinner-border text-gray-600" role="status"></div>
    <span class="text-gray-600 ps-3 fs-2">Loading</span>
  </div>
  <div
    class="pb-3"
    v-if="isAuthorized && !cod_tessera && !fromAnagrafica && !isLoading"
  >
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4">
        <div class="row">
          <div class="col-6">
            <input
              placeholder="Numero tessera"
              class="form-control"
              style="height: 25px !important"
              v-model="codiceTessera"
            />
          </div>
          <div class="text-end col-6">
            <button
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                credentialsTessera.crypt = '';
                info();
              "
              :disabled="isLoading"
            >
              Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="pb-3"
    v-if="!isAuthorized && !isLoading && Object.keys(data).length != 0"
  >
    <div class="pt-4 pb-4 pe-4 ps-4 d-flex flex-column">
      <div class="row justify-content-center">
        <div class="col-sm-3">
          <input
            v-model="credentialsTessera.username"
            placeholder="Username"
            class="form-control"
            style="height: 30px !important"
          />
        </div>
        <div class="col-sm-3">
          <input
            v-model="credentialsTessera.password"
            placeholder="Password"
            type="password"
            class="form-control"
            style="height: 30px !important"
          />
        </div>
        <div class="text-end col-sm-1">
          <button
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge bg-primary rounded text-white fs-5"
            @click.prevent="
              info();
              firstTime = false;
            "
            :disabled="isLoading"
          >
            Accedi
          </button>
        </div>
      </div>
      <p
        v-if="!isAuthorized && !firstTime"
        class="align-self-center mt-3 text-danger"
      >
        Accesso non autorizzato
      </p>
    </div>
  </div>

  <template v-if="Object.keys(data).length != 0 && !isLoading">
    <!-- TESSERA NON GOLD -->
    <div class="card pb-3 mt-0 card shadow" v-if="!data.is_gold">
      <!-- <div class="card pb-3 mt-0"> -->
      <div class="card-body">
        <div class="">
          <div class="row">
            <div class="row" v-if="data.gold_valida">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">TESSERATO GOLD</span>
              </div>
            </div>
            <div class="row" v-if="data.nome_completo">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">COGNOME, NOME:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.nome_completo"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.sesso">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">SESSO:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input class="form-control" :value="data.sesso" disabled />
              </div>
            </div>
            <div class="row" v-if="data.data_nascita">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">DATA DI NASCITA:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="fromUTCtoISODateDMY(new Date(data.data_nascita))"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.cittadinanza">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">CITTADINANZA:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.cittadinanza"
                  disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800"
                  >CITTADINANZA SPORTIVA:</span
                >
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.cittadinanza_sportiva"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.sanzionato != null">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">SANZIONATO:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.sanzionato ? 'SI' : 'NO'"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.abilitato_attivita_sportiva != null">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800"
                  >ABILITATO ATTIVITA' SPORTIVA:</span
                >
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  v-if="data.abilitato_attivita_sportiva"
                  class="form-control"
                  :value="
                    data.abilitato_fino_al
                      ? 'SI - ' + 'abilitato fino al ' + data.abilitato_fino_al
                      : 'SI'
                  "
                  disabled
                />
                <input
                  v-if="!data.abilitato_attivita_sportiva"
                  class="form-control"
                  value="NO"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.numero_tessera">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">NUM.TESSERA:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.numero_tessera"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.tipo_tessera">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">TIPO TESSERA:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.tipo_tessera"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.categoria">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">CATEGORIA:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input class="form-control" :value="data.categoria" disabled />
              </div>
            </div>
            <div class="row" v-if="data.classifica">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">CLASSIFICA:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input class="form-control" :value="data.classifica" disabled />
              </div>
            </div>
            <div class="row" v-if="data.classifica_inizio_anno">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800"
                  >CLASSIFICA INIZIO ANNO:</span
                >
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.classifica_inizio_anno"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.trasferito">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">TRASFERITO:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input class="form-control" :value="data.trasferito" disabled />
              </div>
            </div>

            <div class="row" v-if="data.data_validita">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">DATA TESSERAMENTO:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="fromUTCtoISODateDMY(new Date(data.data_validita))"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.data_validita">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">DATA VALIDITA':</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="fromUTCtoISODateDMY(new Date(data.data_validita))"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.data_scadenza">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">DATA SCADENZA:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="fromUTCtoISODateDMY(new Date(data.data_scadenza))"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.anno_validita">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">ANNO VALIDITA':</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.anno_validita"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.codice_circolo">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">CODICE CIRCOLO:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.codice_circolo"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.residenza_tesserato">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">RESIDENZA TESSERATO:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.residenza_tesserato"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.circolo_appartenenza">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800"
                  >CIRCOLO DI APPARTENENZA:</span
                >
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.circolo_appartenenza"
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.tipo_tessera != 'Socio'">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800"
                  >DATA SCADENZA CERTIFICATO:</span
                >
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="
                    data.scadenza_certificato
                      ? fromUTCtoISODateDMY(new Date(data.scadenza_certificato))
                      : null
                  "
                  disabled
                />
              </div>
            </div>
            <div class="row" v-if="data.tipo_tessera != 'Socio'">
              <div class="pt-2 col-5">
                <span class="fw-bold text-gray-800">VIVAIO:</span>
              </div>
              <div class="pt-2 pe-0 col-7">
                <input
                  class="form-control"
                  :value="data.vivaio ? 'SI' : 'NO'"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TESSERA GOLD -->
    <template v-else>
      <div class="card pb-3 mt-0 shadow">
        <div class="card-body">
          <div class="">
            <div class="row">
              <div class="align-items-center mb-2">
                <span
                  class="badge fw-bolder text-warning bg-light-warning px-4 py-3"
                  v-if="data.gold_valida && !fromAnagrafica"
                >
                  TESSERATO GOLD {{ new Date().getFullYear()
                  }}<i
                    class="bi bi-trophy fs-3 fw-bolder text-warning ps-1"
                  ></i>
                </span>
                <span
                  v-if="!data.gold_valida && !fromAnagrafica"
                  class="badge fw-bolder text-danger bg-light-danger fs-5 me-2"
                >
                  Attenzione tessera Gold non rinnovata per l'anno in corso
                </span>
              </div>

              <div class="row" v-if="data.nome_completo">
                <div class="pt-2 col-5">
                  <span class="fw-bold text-gray-800">COGNOME, NOME:</span>
                </div>
                <div class="pt-2 pe-0 col-7">
                  <input
                    class="form-control"
                    :value="data.nome_completo"
                    disabled
                  />
                </div>
              </div>
              <div class="row" v-if="data.cittadinanza">
                <div class="pt-2 col-5">
                  <span class="fw-bold text-gray-800">CITTADINANZA:</span>
                </div>
                <div class="pt-2 pe-0 col-7">
                  <input
                    class="form-control"
                    :value="data.cittadinanza"
                    disabled
                  />
                </div>
              </div>
              <div class="row" v-if="data.cittadinanza_sportiva">
                <div class="pt-2 col-5">
                  <span class="fw-bold text-gray-800"
                    >CITTADINANZA SPORTIVA:</span
                  >
                </div>
                <div class="pt-2 pe-0 col-7">
                  <input
                    class="form-control"
                    :value="data.cittadinanza_sportiva"
                    disabled
                  />
                </div>
              </div>
              <div class="row" v-if="data.sanzionato != null">
                <div class="pt-2 col-5">
                  <span class="fw-bold text-gray-800">SANZIONATO:</span>
                </div>
                <div class="pt-2 pe-0 col-7">
                  <input
                    class="form-control"
                    :value="data.sanzionato ? 'SI' : 'NO'"
                    disabled
                  />
                </div>
              </div>
              <div class="row" v-if="data.abilitato_attivita_sportiva != null">
                <div class="pt-2 col-5">
                  <span class="fw-bold text-gray-800"
                    >ABILITATO ATTIVITA' SPORTIVA:</span
                  >
                </div>
                <div class="pt-2 pe-0 col-7">
                  <input
                    v-if="data.abilitato_attivita_sportiva"
                    class="form-control"
                    :value="
                      data.abilitato_fino_al
                        ? 'SI - ' +
                          'abilitato fino al ' +
                          data.abilitato_fino_al
                        : 'SI'
                    "
                    disabled
                  />
                  <input
                    v-if="!data.abilitato_attivita_sportiva"
                    class="form-control"
                    value="NO"
                    disabled
                  />
                </div>
              </div>
              <div class="row" v-if="data.scadenza_certificato">
                <div class="pt-2 col-5">
                  <span class="fw-bold text-gray-800"
                    >DATA SCADENZA CERTIFICATO:</span
                  >
                </div>
                <div class="pt-2 pe-0 col-7">
                  <input
                    class="form-control"
                    :value="
                      fromUTCtoISODateDMY(new Date(data.scadenza_certificato))
                    "
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="data.tessere.length">
        <div
          class="card pb-3 mt-3 card shadow"
          v-for="(tessera, index) in data.tessere"
          :key="index"
        >
          <div class="card-body">
            <div class="">
              <div class="row">
                <div class="row" v-if="tessera.cod_tessera">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800">NUM.TESSERA:</span>
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.cod_tessera"
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.tipo_tessera">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800">TIPO TESSERA:</span>
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.tipo_tessera"
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.categoria">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800">CATEGORIA:</span>
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.categoria"
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.classifica">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800">CLASSIFICA:</span>
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.classifica"
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.classifica_inizio_anno">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800"
                      >CLASSIFICA INIZIO ANNO:</span
                    >
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.classifica_inizio_anno"
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.trasferito">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800">TRASFERITO:</span>
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.trasferito"
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.data_validita">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800"
                      >DATA TESSERAMENTO:</span
                    >
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="
                        fromUTCtoISODateDMY(new Date(tessera.data_validita))
                      "
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.data_scadenza">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800">DATA SCADENZA:</span>
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="
                        fromUTCtoISODateDMY(new Date(tessera.data_scadenza))
                      "
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.anno_validita">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800">ANNO VALIDITA:</span>
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.anno_validita"
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.denominazione_circolo">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800"
                      >CIRCOLO DI APPARTENENZA:</span
                    >
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.denominazione_circolo"
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.codice_circolo">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800">CODICE CIRCOLO:</span>
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.codice_circolo"
                      disabled
                    />
                  </div>
                </div>
                <div class="row" v-if="tessera.tipo_tessera != 'Socio'">
                  <div class="pt-2 col-5">
                    <span class="fw-bold text-gray-800">VIVAIO:</span>
                  </div>
                  <div class="pt-2 pe-0 col-7">
                    <input
                      class="form-control"
                      :value="tessera.vivaio ? 'SI' : 'NO'"
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
    <!-- STORICO -->
    <div
      class="card pt-3 mt-3 card shadow"
      v-if="data.storico_classifiche && data.storico_classifiche.length"
    >
      <span class="fw-bold text-gray-800 ms-5"
        >CLASSIFICA {{ totAnni === 1 ? "ULTIMO" : "ULTIMI" }}
        {{ totAnni === 1 ? "" : totAnni }}
        {{ totAnni === 1 ? "ANNO" : "ANNI" }}:
      </span>
      <table class="table gy-3 gs-7 align-middle">
        <thead>
          <tr
            class="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200"
          >
            <th scope="col">Stagione</th>
            <th scope="col">Data Inserimento</th>
            <th scope="col">Categoria</th>
            <th scope="col">Gruppo</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(stagione, index) in data.storico_classifiche"
            :key="index"
          >
            <td>{{ stagione.stagione }}</td>
            <td>
              {{ fromUTCtoISODateDMY(new Date(stagione.data_inserimento)) }}
            </td>
            <td>{{ stagione.categoria }}</td>
            <td>{{ stagione.gruppo }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>

  <div
    v-if="error && !isLoading"
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
    <span class="text-gray-700 ps-3 fs-2">
      Attenzione! Si è verificato un errore. Riprovare più tardi.
    </span>
  </div>
  <div
    v-if="Object.keys(data).length === 0 && !isLoading"
    class="mx-auto my-auto text-center card shadow pt-5 mt-5 mb-5 pb-5"
  >
    <!-- <i class="bi bi-exclamation-triangle fs-2 text-black pb-4"></i> -->
    <span class="text-gray-700 ps-3 fs-2">
      Attenzione tessera non valida nell'anno in corso
    </span>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, watch, computed } from "vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { fromUTCtoISODateDMY } from "@/composables/formatDate";
import { getInfoTesserato, loginQr } from "@/requests/qrCodeInfo";

import { alertFailed } from "@/composables/swAlert";

import { useRoute } from "vue-router";

export default defineComponent({
  name: "qr",
  components: {},
  props: {
    cod_tessera: {
      type: String,
    },
    crypt: {
      type: String,
      default: "",
    },
    reset: {
      type: Number,
    },
  },
  setup(props) {
    const route = useRoute();
    const isAuthenticated = ref(
      localStorage.getItem("tokenAdb2c") ? true : false
    );
    const queryParams = route.query;

    const cookies = document.cookie;
    const fromAnagrafica = ref(route.params.id ? true : false); // controlla se viene da anagrafica persone
    const credentialsFromCookies = cookies.includes("credentialsTessera")
      ? JSON.parse(
          cookies.substring(cookies.indexOf("{"), cookies.indexOf("}") + 1)
        )
      : {};
    const checkUsername = () => {
      console.log(
        isAuthenticated.value,
        "checkUsername",
        localStorage.getItem("tokenAdb2c") ? true : false
      );
      // if (isAuthenticated.value && (props.cod_tessera || fromAnagrafica.value))
      if (isAuthenticated.value) return "0c88028bf3aa6a6a143ed846f2be1ea4";
      // if (props.cod_tessera || fromAnagrafica.value) return "190210";
      else if (credentialsFromCookies.username)
        return credentialsFromCookies.username;
      else return "";
    };
    const checkPassword = () => {
      if (isAuthenticated.value)
        return "d012f68144ed0f121d3cc330a17eec528c2e7d59";
      // if (props.cod_tessera || fromAnagrafica.value) return "190210ct";
      else if (credentialsFromCookies.username)
        return credentialsFromCookies.password;
      else return "";
    };
    // visualizza tessera da management autenticato
    watch(
      () => props.cod_tessera,
      () => {
        if (props.cod_tessera != null) {
          credentialsTessera.value = {
            username: checkUsername(),
            password: checkPassword(),
            cod_tessera: queryParams.k ? queryParams.k : props.cod_tessera,
            crypt: queryParams.CRI ? queryParams.CRI : props.crypt,
          };

          // login();
          info();
        }
      }
    );
    watch(
      () => props.reset,
      () => {
        setTimeout(() => {
          credentialsTessera.value.cod_tessera = "";
          credentialsTessera.value.crypt = "";
          codiceTessera.value = null;
          data.value = {};
          isLoading.value = true;
        }, 500);
      }
    );
    const credentialsTessera = ref({
      username: checkUsername(),
      password: checkPassword(),
      cod_tessera: queryParams.k ? queryParams.k : props.cod_tessera,
      crypt: queryParams.CRI ? queryParams.CRI : props.crypt,
    });
    const credentialsToken = ref({
      username: "pippo",
      password: "pippo",
    });
    const token = ref("");
    const error = ref(false);

    const data = ref({});
    const isLoading = ref(true);
    const isAuthorized = ref(null);
    const firstTime = ref(true);

    const codiceTessera = ref(null);

    const login = () => {
      if (credentialsTessera.value.cod_tessera) {
        loginQr(credentialsToken.value).then(async (res) => {
          if (res.status != 200) {
            alertFailed(
              res.data.message
                ? res.data.message
                : "Attenzione! Si è verificato un errore. Riprovare più tardi"
            );
            isLoading.value = false;
            error.value = true;
            return;
          }
          error.value = false;
          token.value = res.data.access_token;
          await info();
        });
      }
    };

    const info = async () => {
      // isAuthorized.value = true;
      isLoading.value = true;
      await getInfoTesserato(
        credentialsTessera.value,
        token.value,
        codiceTessera.value
      ).then((res) => {
        if (res.status != 200) {
          alertFailed(
            res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore. Riprovare più tardi"
          );
          isLoading.value = false;
          error.value = true;
          return;
        }
        error.value = false;
        isAuthorized.value = res.data.results
          ? res.data.results[0].autenticato
          : false;
        if (isAuthorized.value) {
          const now = ref(new Date());
          now.value.setTime(now.value.getTime() + 1 * 3600 * 1000);
          document.cookie = `credentialsTessera:{"username": "${
            credentialsTessera.value.username
          }", "password": "${
            credentialsTessera.value.password
          }" }; expires= + ${now.value.toUTCString()}`;
        }
        data.value = res.data.results ? res.data.results[0] : {};
        isLoading.value = false;
      });
    };

    onMounted(() => {
      if (!fromAnagrafica.value)
        setCurrentPageBreadcrumbs("VERIFICA TESSERA", []);
      // login();

      if (queryParams.k || props.cod_tessera) {
        credentialsTessera.value = {
          username: checkUsername(),
          password: checkPassword(),
          cod_tessera: queryParams.k ? queryParams.k : props.cod_tessera,
          crypt: queryParams.CRI ? queryParams.CRI : props.crypt,
        };
        info();
      }
    });

    const totAnni = computed(() => {
      return data.value.storico_classifiche != null &&
        data.value.storico_classifiche != []
        ? data.value.storico_classifiche[0].stagione -
            data.value.storico_classifiche[
              data.value.storico_classifiche.length - 1
            ].stagione +
            1
        : "";
    });

    return {
      totAnni,
      data,
      error,
      fromUTCtoISODateDMY,
      isLoading,
      login,
      info,
      credentialsTessera,
      isAuthorized,
      firstTime,
      codiceTessera,
      fromAnagrafica,
    };
  },
});
</script>

<style scoped>
.form-control {
  height: 22px !important;
}

.badge {
  white-space: normal;
}
</style>
